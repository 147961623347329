.appTheme {
  // color
  --black: #000000;
  --white: #ffffff;
  --grey-1: #c0c0c0;

  --color-primary-variant: var(--black);
  --color-secondary-variant: #030303;
  --color-tertiary-variant: #ef5533;
  --color-amaranth-variant: #eb4424;
  --color-moderate-blue-variant: #4183c4;

  --border-radius-base: 3px;

  // font-family
  --font-family-text: 'Inter', sans-serif;
  --font-family-heading: 'Fraunces', serif;

  // font-size
  --font-l: 18px;
  --font-m: 16px;
  --font-s: 14px;
  --font-xs: 12px;
  --font-2xs: 11px;

  --font-heading-1: 80px;
  --font-heading-2: 60px;
  --font-heading-3: 48px;
  --font-heading-4: 36px;
  --font-heading-5: 28px;
  --font-heading-6: 20px;
  --font-secondary-1: 60px;
  --font-secondary-2: 48px;
  --font-secondary-3: 36px;
  --font-secondary-4: 24px;

  // font-weight
  --font-weight-extra-light: 200;
  --font-weight-light: 300;
  --font-weight-normal: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;

  // line-height
  --line-height-base: 1.2;
  --line-height-text: 1.5;

  // spacing
  --space-2xl: 24px;
  --space-xl: 20px;
  --space-l: 16px;
  --space-m: 12px;
  --space-s: 8px;
}
