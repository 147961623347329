h1 {
  @include typo-headline("h1");
}

h2 {
  @include typo-headline("h2");
}

h3 {
  @include typo-headline("h3");
}

h4 {
  @include typo-headline("h4");
}

h5 {
  @include typo-headline("h5");
}

h6 {
  @include typo-headline("h6");
}
