.link {
  cursor: pointer;
  font-family: var(--font-family-text);
  text-decoration: none;
  color: inherit;

  &:hover {
    opacity: 0.7;
    color: inherit; // TODO: should be removed after removing semantic-ui-sass lib
  }

  &--v-primary {
    color: var(--color-amaranth-variant);
    font-weight: var(--font-weight-medium);

    // TODO: should be removed after removing semantic-ui-sass lib
    &:hover {
      color: var(--color-amaranth-variant);
    }
  }

  &--v-underline {
    text-decoration: underline;

    // TODO: should be removed after removing semantic-ui-sass lib
    &:hover {
      text-decoration: underline;
    }
  }

  &--v-inherit {
    &:hover {
      text-decoration: underline;
    }
  }

  &--v-secondary {
    color: var(--color-moderate-blue-variant);

    // TODO: should be removed after removing semantic-ui-sass lib
    &:hover {
      color: var(--color-moderate-blue-variant);
    }
  }

  &--s-x-large {
    font-size: 36px;
    line-height: var(--line-height-base);
  }

  &--s-large {
    font-size: var(--font-m);
    line-height: var(--line-height-text);
  }

  &--s-small {
    font-size: var(--font-s);
    line-height: var(--line-height-text);
  }

  &--s-inherit {
    font-size: inherit;
    line-height: var(--line-height-base);
  }
}
