.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-text);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-text);
  text-transform: initial;
  transition: all 0.3s ease;
  outline: 0;
  box-sizing: border-box;
  white-space: nowrap;
  cursor: pointer;

  &:disabled {
    background-color: var(--white);
    border: 1px solid var(--grey-1);
    color: var(--black);
    opacity: 0.5;
    cursor: initial;
    pointer-events: none;
  }

  &--v-primary,
  &--v-minimal {
    background-color: var(--black);
    border: 1px solid var(--black);
    color: var(--white);

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: var(--white);
        color: var(--black);
      }
    }
  }

  &--v-secondary {
    background-color: transparent;
    border: 1px solid var(--black);
    color: var(--color-secondary-variant);

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: var(--color-secondary-variant);
        color: var(--white);
      }
    }
  }

  &--v-secondary-inverted {
    background-color: transparent;
    border: 1px solid var(--white);
    color: var(--white);

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: var(--white);
        color: var(--black);
      }
    }
  }

  &--v-tertiary {
    background-color: var(--color-tertiary-variant);
    border: 1px solid var(--color-tertiary-variant);
    color: var(--white);

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: var(--white);
        color: var(--color-tertiary-variant);
      }
    }
  }

  &--v-tertiary-outline {
    background-color: transparent;
    border: 1px solid var(--color-tertiary-variant);
    color: var(--color-tertiary-variant);

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: var(--color-tertiary-variant);
        color: var(--white);
      }
    }
  }

  &--v-text {
    background-color: transparent;
    border: none;
    color: var(--black);

    @media (hover: hover) {
      &:hover:not(:disabled) {
        opacity: 0.6;
      }
    }

    &:disabled {
      background-color: transparent;
      border: none;
    }
  }

  &--s-small {
    --button-height: 40px;

    font-size: var(--font-s);
    height: var(--button-height);
    padding: var(--space-m) var(--space-l);
  }

  &--s-medium {
    --button-height: 50px;

    font-size: var(--font-m);
    height: var(--button-height);
    padding: var(--space-l) var(--space-xl);
  }

  &--s-large {
    --button-height: 60px;

    font-size: var(--font-l);
    height: var(--button-height);
    padding: var(--space-xl) var(--space-2xl);
  }

  &--shape-rounded {
    border-radius: calc(var(--button-height) / 2);
  }

  &--iconOnly {
    width: var(--button-height);
    padding: 0;
  }

  &--fullWidth {
    width: 100%;
  }
}
