.page-header {
  @include mq($from: tablet) {
    min-height: 124px;
  }
  background: $c-white;
  border-bottom: 1px solid $c-line;
  display: flex;
  flex-direction: column;
  min-height: 124px;
}

.page-header__inner {
  @include mq($until: tablet) {
    align-items: flex-start;
    flex-direction: column-reverse;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  @include wrapper();
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
  padding-top: 32px;
  padding-bottom: 24px;
  .icon-bar {
    @include mq($until: tablet) {
      align-self: flex-end;
    }
  }
}

.page-header__hl {
  margin: 0;
  margin-bottom: -0.24em !important;
}

.page-header__sl {
  // @include typo-overline();
  // font-size: $font-size-s;
  // font-weight: 500;
  // margin-bottom: -.25em;
}
