/** WRAPPER **/
@mixin wrapper($tight: false) {
  @include mq($from: tablet) {
    padding-left: 32px;
    padding-right: 32px;
  }
  margin-left: auto;
  margin-right: auto;
  max-width: 100%;
  padding: 0 16px;
  width: 1440px;
  @if $tight {
    width: 960px;
  }
}
.wrapper {
  @include wrapper();
}

/** ROW **/
@mixin row() {
  margin-left: -12px;
  margin-right: -12px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.row {
  @include row();
  &--center {
    justify-content: center;
  }
}

/** CELL **/
@mixin cell() {
  flex: 1 0 100%;
  padding: 12px;
  max-width: 100%;
  // img {
  //   height: auto;
  //   max-width: 100%;
  // }
  &.cell--center {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

$cellSizes: 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12;
@each $size in $cellSizes {
  .cell-#{$size} {
    @include mq($from: desktop) {
      flex-basis: 100% / 12 * $size;
      max-width: 100% / 12 * $size;
    }
    @include cell();
  }
}
