html,
body {
  font-smoothing: antialiased;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $c-black;
  font-family: $font-family;
  font-size: $font-size-m;
  font-weight: $font-weight-regular;
  height: 100%;
}

body {
  &.is-fixed {
    overflow-y: hidden;
  }
}

.app {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: #fff;
}

.app-wrapper {
  display: flex;
  height: 100%;

  &.is-column {
    flex-direction: column;
  }
}
