.card {
  background-color: #fff;
  border-radius: $border-radius;
  box-shadow: 0 0 0.25em $c-drop-shadow; //, 0px -1px 2px $c-drop-shadow;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  // margin: 24px 0;
  .is-dark {
    background: $c-background-dark;
    color: $c-white;
  }
  &.form {
    overflow: visible;
  }
  + .card,
  + .table {
    margin-top: 24px;
  }
}

.card__header {
  border-bottom: 1px solid $c-line;
  // font-family: $font-family-hl;
  // font-weight: 500;
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 48px;
  padding: 0 16px;
  position: relative;
}

.card__inner {
  display: flex;
  flex: 1;
  margin: 16px 0;
  padding: 0 16px;
  &--no-padding {
    padding: 0;
    margin: 0;
  }
  &--column {
    flex-direction: column;
  }
}

.card__message {
  padding-top: 1em;
  padding-bottom: 2em;
  text-align: center;
}

.card__buttons {
  display: flex;
  justify-content: center;
  > * {
    margin: 0 8px;
  }
}
