.page {
  height: 100%;
  flex: 1;
  position: relative;
  width: 100%;
  padding-top: 100px;
}

.page__content {
  background-color: $c-background;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: auto;
  min-height: 100%;
  // margin: 40px 0;
  &--gradient {
    // background: linear-gradient(0deg, $c-background 0%, $c-white 100%);
  }
}

.page__inner {
  @include wrapper();
  @include mq($from: tablet) {
    margin-top: 32px;
    margin-bottom: 32px;
  }
  margin-top: 24px;
  margin-bottom: 24px;
  > *:first-child {
    margin-top: 0;
  }
  &--tight {
    @include wrapper(true);
  }
  &--center {
    margin-top: auto;
    margin-bottom: auto;
  }
  // .canvas-page & {
  //   @include mq($until: tablet) {
  //     margin-top: 0;
  //   }
  // }
}

.page__back-button {
  @include wrapper();
  @include mq($from: tablet) {
    border-bottom: none;
    background: transparent;
  }
  align-items: center;
  background: $c-white;
  border-bottom: 1px solid $c-line;
  display: flex;
  padding-top: 16px;
  padding-bottom: 16px;
  svg {
    font-size: 1.2em;
    transform: translateY(-1px);
  }
  a {
    // @include typo-button();
    color: $c-black;
  }
  .is-desktop {
    @include mq($from: tablet) {
      display: inline-block;
    }
    display: none;
  }
  .is-mobile {
    @include mq($from: tablet) {
      display: none;
    }
  }
}
