.wrapper {
  --text-color-hover: #5c5c5c;

  .navTabs {
    position: relative;
    display: flex;
    transition: all 0.3s;

    .tab {
      cursor: pointer;
      font-family: var(--font-family-text);
      font-weight: 500;
      font-size: var(--font-m);
      line-height: var(--line-height-base);
      text-align: center;
      padding: var(--space-l);

      &:hover:not(.active) {
        color: var(--text-color-hover);
      }
    }

    .active {
      cursor: default;
      border-bottom: 2px solid var(--black);
    }

    .disabled {
      color: var(--text-color-hover);
      cursor: default;
      pointer-events: none;
    }
  }

  .content {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    padding-top: var(--space-l);
  }
}
