$font-family-hl: "Helvetica Neue", "sans-serif";
//$font-family-hl: "Roboto";
$font-family: "Inter", sans-serif;
$font-family-hl: "Avenir";

$font-size-h3--desktop: 48px;
$font-size-h3: 32px;

$font-sizes: (
  "h1": 40px,
  // 96px
  "h2": 52px,
  // 60px
  "h3": 32px,
  "h4": 32px,
  "h5": 24px,
  //
  "h6": 16px,
  "page-header": 34px,
  "navigation": 16px,
  "table-heading": 16px,
  "back-button": 14px,
);

$font-sizes-tablet: (
  "h1": 48px,
  // 96px
  "h2": 60px,
  // 60px
  "h3": 40px,
  "h4": 40px,
  "h5": 24px,
  //
  "h6": 19px,
  "page-header": 34px,
  "navigation": 16px,
  "table-heading": 16px,
  "back-button": 14px,
);

$font-weight-regular: 400;
$font-weight-medium: 500;

$font-weights: (
  "h1": 300,
  // 96px
  "h2": 400,
  // 60px
  "h3": 400,
  "h4": 400,
  "h5": 400,
  //
  "h6": 400,
  "button": 400,
);

$font-size-xl: 24px;
$font-size-l: 19px;
$font-size-m: 16px;
$font-size-s: 14px;
$font-size-xs: 12px;

$letter-spacing-button: 0;
$letter-spacing-overline: 0.08em;
