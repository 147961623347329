.section {
  margin: 40px 0;
}

.section__header {
  // @include typo-overline();
  align-items: center;
  display: flex;
  font-size: map-get($font-sizes, "table-heading");
  // font-weight: 500;
  justify-content: space-between;
  margin-bottom: 12px;
  button {
    font-size: 0.8em;
  }
  // &--bigger {
  //   span {
  //     font-size: $font-size-xl;
  //   }
  // }
}

.section--delete {
  margin-top: 120px;
}
