@mixin typo-button() {
  font-size: $font-size-s;
  letter-spacing: $letter-spacing-button;
  font-family: $font-family;
  font-weight: map-get($font-weights, "button");
  letter-spacing: $letter-spacing-button;
  text-transform: uppercase;
}

@mixin typo-overline() {
  font-size: $font-size-xs;
  letter-spacing: $letter-spacing-overline;
  text-transform: uppercase;
  transform: translateX(1px);
}

@mixin typo-headline($key) {
  @include mq($from: tablet) {
    font-size: map-get($font-sizes-tablet, $key);
  }
  font-family: $font-family-hl;
  font-size: map-get($font-sizes, $key);
  font-weight: map-get($font-weights, $key);
  line-height: 1.1;
}
