.button-icon {
  @include mq($from: tablet) {
    font-size: 1em;
  }
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  font-size: 1.2em;
  &:hover {
    opacity: 0.8;
  }
}
