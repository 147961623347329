@import-normalize;
@import 'semantic-ui-sass'; // TODO: need to check and remove

@import 'variables/colors';
@import 'variables/easing';
@import 'variables/font';
@import 'variables/metrics';
@import 'variables/z-index';
@import 'variables/mq';

// MEDIA QUERY LIB
@import '~sass-mq/mq';

// MIXINS
@import 'mixins/typo';
@import 'mixins/grid-system';

// COMMONS
@import 'common/reset';
@import 'common/typo';
@import 'common/card';
@import 'common/section';

// SHELL
@import 'shell/index';

// MODULES

@import 'modules/button/index';
@import 'modules/loader/loader';
@import 'modules/page/index';
