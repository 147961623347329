@mixin button-typo() {
  font-weight: 500;
  letter-spacing: 0.08em;
  line-height: 1;
  text-transform: uppercase;
  font-family: $font-family;
  font-size: $font-size-s;
  white-space: nowrap;
}

button {
  @include button-typo();
  background: none;
  border: none;
  padding: 0;
}

.button {
  @include button-typo();
  align-items: center;
  background-color: $c-button-bg;
  border: none;
  border-radius: 0;
  color: $c-button-color;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  min-width: 160px;
  padding: 0 24px 0;
  transition: transform 0.2s $easeOutCubic, opacity 0.2s $easeOutCubic;
  &:hover {
    opacity: $opacity-link;
    // transform: translate3d(0, -0.2em, 0);
  }
  &:active:hover {
    // background-color: ;
  }
  &[disabled] {
    opacity: 0.3;
  }
  &:focus {
    outline: none;
  }
  &.is-red {
    background-color: $c-error-bg;
    color: $c-error;
    svg {
      fill: $c-error;
    }
  }

  &.is-green {
    background-color: $c-green-bg;
    color: $c-green-color;
  }

  &.is-redish {
    background-color: $c-red-bg;
    color: $c-red;
  }
  svg {
    color: inherit;
    margin-right: 8px;
  }
}

.card + .button {
  margin-top: 16px;
}

.button--bigger {
  height: 48px;
}

.button--full-width {
  width: 100%;
}

.button-icon {
  @include mq($from: tablet) {
    font-size: 16px;
  }
  border: none;
  background-color: transparent;
  font-size: 24px;
  padding: 0;
}

// text
.button-text {
  @include button-typo();
  color: $c-primary;
  cursor: pointer;
  transition: transform 0.2s $easeOutCubic, opacity 0.2s $easeOutCubic;
  &:hover {
    // border-bottom: 1px solid $c-primary;
    opacity: $opacity-link;
  }
  svg {
    // font-size: 0.9em;
    margin-right: 8px;
    transform: translateY(-1px);
  }
}
