$c-grey: (
  0: #ffffff,
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  1000: #000000,
);

$c-blue-grey: (
  0: #ffffff,
  50: #eceff1,
  100: #cfd8dc,
  200: #b0bec5,
  300: #90a4ae,
  400: #78909c,
  500: #607d8b,
  600: #546e7a,
  700: #455a64,
  800: #37474f,
  900: #263238,
);

$c-primary-color: $c-grey;
$c-primary-text: map-get($c-primary-color, 900);
$c-primary-text-inverted: map-get($c-primary-color, 0);

$c-secondary-color: $c-blue-grey;
$c-secondary-text: map-get($c-secondary-color, 900);

// basic color definition
$c-white: #ffff;
$c-primary: map-get($c-grey, 900);
$c-black: map-get($c-grey, 1000);
$c-black-light: map-get($c-grey, 700);

$c-grey-light: #edf0f0;

$c-offwhite: #f3f6f6;
$c-offwhite-darker: #f2f5f5;

$c-red: #d50000;
$c-green: #00c853;

// $c-line: map-get($c-grey, 300);
// $c-line-hard: map-get($c-grey, 300);
$c-line: #e4e4e4;
$c-box-shadow: rgba(map-get($c-blue-grey, 50), 0.3);

$c-button-bg: map-get($c-grey, 200);
$c-button-color: $c-primary;
$c-background: $c-offwhite;
// $c-background: #F4F4F4;
$c-background-dark: map-get($c-grey, 900);
$c-drop-shadow: rgba(map-get($c-blue-grey, 200), 0.6);
$c-error-bg: rgba($c-red, 0.1);
$c-error: $c-red;
$c-red-bg: rgba($c-red, 0.1);
$c-red: $c-red;
$c-green-bg: rgba($c-green, 0.1);
$c-green-color: $c-green;
$c-input-border: map-get($c-grey, 200);
$c-input-border-focus: map-get($c-grey, 300);
$c-media-bg: map-get($c-grey, 0);
$c-media-new-bg: map-get($c-grey, 300);
$c-table-heading: $c-primary;
$c-table-hover: $c-offwhite-darker;

$opacity-link: 0.6;
