.button-icon-label {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: auto;
  // font-weight: $font-weight-regular;
  // text-transform: none;
  font-size: $font-size-xs;
  &:hover {
    opacity: $opacity-link;
  }
  &.is-filling {
    height: 100%;
    width: 100%;
  }
}

.button-icon-label__inner {
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.button-icon-label__icon {
  // border: 1px solid rgba($c-black, .8);
  font-size: 1em;
  font-size: 1.6em;
  margin: 16px auto 12px;
  .is-white & {
    svg {
      fill: $c-white;
    }
  }
}

.button-icon-label__label {
  bottom: 0;
  .is-filling & {
    bottom: -16px;
    position: absolute;
  }
}
